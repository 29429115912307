import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import SEO from "@src/components/SEO";
import SchoolCommunityContent from "@src/components/partials/school-community/Content";

const SchoolCommunityPage: React.FC = () => {
  const gqlData = useStaticQuery(graphql`
    {
      directus {
        page_school_community {
          primary_cta_url
          secondary_cta_url
        }
      }
    }
  `);

  const {
    directus: {
      page_school_community: { primary_cta_url: primaryCtaUrl, secondary_cta_url: secondaryCtaUrl },
    },
  } = gqlData;

  const videoUrl = "https://static.classdojo.com/uploads/1fc31231-6f49-49db-9e87-e4afc4e86006.mp4";

  const props = { videoUrl, primaryCtaUrl, secondaryCtaUrl };
  return (
    <>
      <SEO
        title="directus.page_school_community.SEO_title"
        description="directus.page_school_community.SEO_description"
      />
      <SchoolCommunityContent {...props} />
    </>
  );
};

export default SchoolCommunityPage;
